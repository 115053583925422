














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Label extends Vue {
  // ラベルのname属性
  @Prop({ default: '' })
  private name!: string;
  // ラベル内テキスト
  @Prop({ default: '' })
  private text!: string;
  // 追加付与クラス
  @Prop({ default: '' })
  private labelClass?: string;
}
