




































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Input from '@/components/common/elements/Input.vue';
import Select from '@/components/common/elements/Select.vue';
import Label from '@/components/common/elements/Label.vue';
import { SelectProps } from '@/constants/form/types';

@Component({
  components: { Input, Select, Label }
})
export default class SelectArea extends Vue {
  // name属性
  @Prop({ default: '' })
  private name!: string;
  // placeholder属性
  @Prop({ default: '' })
  private placeholder?: string;
  // ラベル内テキスト
  @Prop({ default: '' })
  private label!: string;
  // 初期選択value
  @Prop({ default: '' })
  private value?: string;
  // 読み取り専用か
  @Prop({ default: false })
  private isReadonly?: boolean;
  // 選択肢が空の場合の文言
  @Prop({ default: '' })
  private noDataText?: string;

  @Prop({ default: false })
  private isDisabled?: boolean;

  @Prop({ default: () => ({ name: '', options: [] }) })
  private selectData!: SelectProps;

  @Prop({ default: '' })
  private rules?: string;

  @Prop({ default: false })
  private multiple?: boolean;

  @Prop({ default: () => [] })
  public arrayValue?: { [k: string]: string }[];

  private type = 'select';

  @Watch('value')
  watchVal() {
    const val = this.value === undefined ? '' : this.value;
    const find = this.selectData.options.find(v => v.value === val);
    this.inputOperation({
      name: this.name,
      value: val,
      type: this.type,
      text: find ? (find.text as string) : ''
    });
  }

  @Emit('inputOperation')
  public inputOperation(value: { [k: string]: any }) {
    value.type = this.type;
    return value;
  }
}
