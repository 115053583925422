





































































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import SelectArea from '@/components/common/modules/SelectArea.vue';
import SigninModel from '@/models/signinModel';
import authState from '@/store/auth';
import hexabaseState from '@/store/hexabase';
import userState from '@/store/user';
import appState from '@/store/app';

interface MyComponent extends Vue {
  prev: Route;
}

/**
 * 対象とするアプリケーションのDisplay ID
 * （Hexabase UIでは「アプリケーション名(en)」）
 */
const TARGET_APPLICATION_DISPLAY_ID = 'APP-VueBaseApp';

@Component({
  components: { SelectArea },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      (vm as MyComponent).prev = from;
    });
  }
})
export default class Signin extends Vue {
  private signinModel = new SigninModel();

  /** フォーム入力: メールアドレス */
  email = '';
  /** フォーム入力: パスワード */
  password = '';

  /** 各種エラーメッセージ */
  errorMessage = '';
  /** パスワードを見せるかどうか。デフォルトはfalse（見せない） */
  isShowPassword = false;
  /** ワークスペース選択フォームで選択されたワークスペースID */
  selectedWorkspaceId = '';
  /** ワークスペース選択ドロップダウンを生成するために必要な情報 */
  workspaceSelectData = {
    label: '',
    name: 'select',
    disabled: false,
    options: [{ value: '', text: '' }]
  };
  /** 選択可能なワークスペース情報 */
  workspaces: Array<{ [k: string]: string }> = [];
  /** 遷移前ページ情報 */
  prev!: Route;

  mounted() {
    if (this.prev.name !== null && this.prev.name === 'ResetPassword') {
      this.errorMessage = '再度サインインしてください。';
    }
  }

  async signin() {
    this.errorMessage = '';
    try {
      // Hexabaseへログイン、tokenをstateへ永続化
      const token = (await this.signinModel.login(this.email, this.password)) || '';
      authState.setToken(token);

      // HexabaseからUserInfoを取得、UserInfoをstateへ永続化
      const userInfo = await this.signinModel.getUserInfo();
      userState.setUserName(userInfo.username);
      userState.setUserEmail(userInfo.email);
      // ユーザーのロール権限がADMINか否か
      if (
        userInfo.user_roles.find((roles: { [k: string]: string }) => roles.role_id === 'ADMIN') !==
        undefined
      ) {
        userState.setIsAdmin(true);
      }

      // workspaceの取得
      this.workspaces = await this.signinModel.getWorkspaces();
      if (this.workspaces.length === 1) {
        // ワークスペースが1つだけの場合、選択は不要なのでそのままTOP画面へそのまま遷移する
        this.initialSignin(this.workspaces[0].workspace_id);
      } else {
        // ワークスペースが複数存在する場合、ワークスペース選択ドロップダウンを構成して表示
        for (const key in this.workspaces) {
          this.workspaceSelectData.options.push({
            value: this.workspaces[key].workspace_id,
            text: this.workspaces[key].workspace_name
          });
        }
      }
    } catch (e) {
      this.errorMessage = 'メールアドレスもしくはパスワードが違います。';
    }
  }

  async selectWorkspace() {
    this.errorMessage = '';
    this.initialSignin(this.selectedWorkspaceId);
  }

  async initialSignin(workspaceId: string) {
    try {
      // ワークスペースIDをstateへ永続化
      hexabaseState.setWorkspaceId(workspaceId);

      // 利用可能アプリケーション情報の取得
      const applications = (await this.signinModel.getApplications()) as Array<{
        [k: string]: string | Array<{ [k: string]: string }>;
      }>;
      if (applications.length === 0) {
        this.errorMessage = '利用可能なアプリケーションがありません。';
        return;
      }

      // アプリケーションの選択
      const targetApp = applications.find(v => v.display_id === TARGET_APPLICATION_DISPLAY_ID);
      // アプリケーションIDをstateへ永続化（display_idで一致するアプリケーションがない場合、リストの先頭にあるアプリケーション情報を利用）
      const applicationId = (targetApp !== undefined
        ? targetApp.application_id
        : applications[0].application_id) as string;
      hexabaseState.setApplicationId(applicationId);
      // データストア情報をstateへ永続化（display_idで一致するアプリケーションがない場合、リストの先頭にあるアプリケーション情報を利用）
      const datastores = (targetApp !== undefined
        ? targetApp.datastores
        : applications[0].datastores) as Array<{ [k: string]: string }>;
      if (datastores === null) {
        this.errorMessage = '利用可能なデータベースがありません。';
        return;
      }

      const datastoreIds: { [k: string]: string } = {};
      datastores.forEach((datastore: { [k: string]: string }) => {
        datastoreIds[datastore.name] = datastore.datastore_id;
      });
      hexabaseState.setDatastoreIds(datastoreIds);

      const params = {
        conditions: [
          {
            id: 'email',
            search_value: [userState.userEmail],
            exact_match: true
          }
        ],
        per_page: 1,
        page: 1,
        use_display_id: true,
        include_lookups: true
      };
      const userEmployeeInfo = await this.signinModel.getItemList(
        hexabaseState.applicationId,
        hexabaseState.datastoreIds['社員マスタ'],
        params
      );

      userState.setUserEmployeeName('-');
      if (userEmployeeInfo.length > 0) {
        userState.setUserEmployeeName(
          userEmployeeInfo[0].name ? userEmployeeInfo[0].name : userState.getUserEmployeeName
        );
      }

      // 管理マスタの定数群取得
      if ('管理マスタ' in datastoreIds) {
        const optionList = await this.signinModel.getApplicationOptions(
          applicationId,
          datastoreIds['管理マスタ']
        );
        appState.setOptions(optionList);
      }

      // ログイン後画面に遷移
      this.$router.push({ name: 'Doc_list' });
    } catch (e) {
      this.errorMessage = 'エラーが発生しました。';
    }
  }

  onSelectWorkspace(workspaceId: string): void {
    this.selectedWorkspaceId = workspaceId;
  }
}
