
























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { SelectProps, InputResponse } from '@/constants/form/types';

@Component({})
export default class Select extends Vue {
  // name属性
  @Prop({ default: '' })
  private name!: string;
  // placeholder属性
  @Prop({ default: '' })
  private placeholder?: string;
  // セレクトリストデータ
  @Prop({ default: () => ({ name: '', options: [] }) })
  public selectData!: SelectProps;
  // 選択可能か制御
  @Prop({ default: false })
  public isDisabled?: boolean;
  // 読み取り専用か
  @Prop({ default: false })
  public isReadonly?: boolean;
  // 入力要素名
  @Prop({ default: '' })
  public label?: string;
  // 初期選択value
  @Prop()
  public value?: { [k: string]: string };
  @Prop({ default: () => [] })
  public arrayValue?: { [k: string]: string }[];

  @Prop({ default: '' })
  private rule?: string;

  // 複数選択時Value
  @Prop({ default: false })
  private multiple?: boolean;
  // 選択肢が空の場合の文言
  @Prop({ default: '' })
  private noDataText?: string;

  public selected: { [k: string]: string } = {};
  public selectedArray: { [k: string]: string }[] = [];

  mounted() {
    if (this.value) {
      this.selected = this.value;
    }
    if (this.arrayValue !== undefined) {
      this.selectedArray = this.arrayValue;
    }
  }

  // 明細行削除時対応の為ValueをWatch
  @Watch('value')
  watchValue() {
    if (this.value !== undefined) {
      this.selected = this.value;
    }
  }

  // マルチ選択対応
  @Watch('arrayValue')
  watchselectedArray() {
    if (this.arrayValue !== undefined) {
      this.selectedArray = this.arrayValue;
    }
  }

  /**
   * 親コンポーネントに選択値を通知する
   * @module changeSelect
   * @return InputResponse - 要素名と入力値
   */
  @Emit('changeSelect')
  public changeSelect(): InputResponse {
    if (this.multiple) {
      return { name: this.name, value: '', data: this.selectedArray };
    }

    return { name: this.name, value: this.selected.value, text: this.selected.text };
  }

  createName() {
    if (this.label !== undefined) {
      const keyStr = this.name.match(/\[\d{1,}\]/);
      return keyStr !== null ? this.label + keyStr : this.label;
    }
    return '';
  }
  createMessage(errors: any) {
    const result: string[] = [];
    for (const i in errors) result.push(errors[i].replace(/\[\d{1,}\]/, ''));
    return result;
  }
}
